$(document).on('submit', '#reset-pass-form', function (e) {
    e.preventDefault()

    let email = $(this).find('input[name=email]').val()
    let url = $(this).attr('action')
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let btn = $(this).find('button[type=submit]')
    let btnTextDefault = $(btn).text()
    let btnTextProcess = $(btn).attr('data-process')
    let btnTextSuccess = $(btn).attr('data-success')
    let btnTextError = $(btn).attr('data-error')

    let sendData = {
        email: email,
        csrf: csrf
    }

    $(btn).text(btnTextProcess)
    $(btn).attr('disabled', true)

    $.ajax({
        url: url,
        method: 'post',
        headers: {
            'X-CSRF-TOKEN': csrf,
        },
        data: sendData,
        success: function (data) {
            $(btn).text(btnTextSuccess)
            if (data.status === true) {
                $('#password-reset-request-step').hide()
                $('#password-reset-code-step').show()

            } else {
                $(btn).text(btnTextError)
            }

            setTimeout(function () {
                $(btn).attr('disabled', false)
                $(btn).text(btnTextDefault)
            }, 3000)
        },
        error: function (xhr, status, error) {
            var errors = JSON.parse(xhr.responseText);

            setTimeout(function () {
                $(btn).attr('disabled', false)
                $(btn).text(btnTextDefault)
            }, 2000)
            alert(errors.message)

        }
    });
})

$(document).on('submit', '#reset-pass-create', function (e) {
    e.preventDefault()

    let pass = $(this).find('input[name=password]').val()
    let passComfirm = $(this).find('input[name=password_confirmation]').val()
    let code = $(this).find('input[name=code]').val()
    let url = $(this).attr('action')
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let btn = $(this).find('*[type=submit]')
    let btnTextDefault = $(btn).val()
    let btnTextProcess = $(btn).attr('data-process')
    let btnTextSuccess = $(btn).attr('data-success')
    let btnTextError = $(btn).attr('data-error')

    if (pass !== passComfirm) {
        return alert('Password and Password Confirmation does not match')
    }

    let sendData = {
        password: pass,
        password_confirmation: passComfirm,
        code: code,
        csrf: csrf
    }

    $(btn).val(btnTextProcess)
    $(btn).attr('disabled', true)

    $.ajax({
        url: url,
        method: 'post',
        headers: {
            'X-CSRF-TOKEN': csrf,
        },
        data: sendData,
        success: function (data) {
            $(btn).val(btnTextSuccess)
            if (data.status === 'passwords.reset') {
                alert('Password was updated!')
                $('#memberstack-forgot-portal').hide()
                $(btn).attr('disabled', false)
            } else {
                $(btn).val(btnTextError)
                $(btn).attr('disabled', false)
            }

            setTimeout(function () {
                $(btn).val(btnTextDefault)
                $(btn).attr('disabled', false)
            }, 7000)
        },
        error: function (xhr, status, error) {
            var errors = JSON.parse(xhr.responseText);

            // let errorsString = ''
            // for (const [key, value] of Object.entries(errors)) {
            //     for (const [valueKey, valueData] of Object.entries(value)) {
            //         let string = key + ': ' + valueData + '\n'
            //         errorsString += string
            //     }
            // }

            setTimeout(function () {
                $(btn).attr('disabled', false)
                $(btn).val(btnTextDefault)
            }, 2000)
            alert(errors.message)

        }
    });
})


$(document).on('submit', '#search_resident_form', function (e) {
    e.preventDefault()

    let url = $(this).attr('action')
    let csrf = $(this).find('input[name=_token]').val()
    let searchInput = $(this).find('input[name=search_residents]')
    let houseId = $(this).find('input[name=house_id]').val()

    let btn = $(this).find('*[type=submit]')
    let btnTextDefault = $(btn).attr('data-default')
    let btnTextProcess = $(btn).attr('data-process')
    let btnTextSuccess = $(btn).attr('data-success')
    let btnTextError = $(btn).attr('data-error')

    if ($(searchInput).val().length > 2) {
        let sendData = {
            csrf: csrf,
            search: $(searchInput).val()
        }

        $(btn).text(btnTextProcess)
        $(btn).attr('disabled', true)

        $.ajax({
            url: url,
            method: 'get',
            headers: {
                'X-CSRF-TOKEN': csrf,
            },
            data: sendData,
            success: function (response) {

                $('#search-resident-list').html('')
                $('#search-resident-list__container').fadeIn()

                if (Object.keys(response.data).length !== 0) {
                    for (const [key, value] of Object.entries(response.data)) {
                        let str = '<b>' + value.f_name + '</b>'

                        if (value.email !== null) {
                            str += ' ' + value.email
                        }

                        if (value.phone !== null) {
                            str += ' ' + value.phone
                        }

                        let status = ''
                        if (Object.keys(value.houses).length !== 0 && value.houses !== null) {
                            for (const [objKey, objValue] of Object.entries(value.houses)) {
                                if (objValue.id === Number(houseId)) {
                                    status = 'disabled'
                                }
                            }
                        }

                        let userBtn = '<button class="js-add-to-house" data-user-id="' + value.id + '" ' + status + '>' + str + '</button>'
                        $('#search-resident-list').append(userBtn)
                    }
                } else {
                    let htmlString = '<span style="color: red;">' + $('#search-resident-list').attr('data-empty') + '</span>'

                    $('#search-resident-list').html(htmlString)

                    setTimeout(function () {
                        $('#search-resident-list').html('')
                    }, 3000)
                }

                $(btn).text(btnTextSuccess)

                setTimeout(function () {
                    $(btn).text(btnTextDefault)
                    $(btn).attr('disabled', false)
                }, 1000)
            },
            error: function (xhr, status, error) {
                var errors = JSON.parse(xhr.responseText);
                alert(errors.message)
            }
        });
    } else {
        $(btn).text(btnTextError)
        $(btn).addClass('btn-error')

        setTimeout(function () {
            $(btn).text(btnTextDefault)
            $(btn).removeClass('btn-error')
        }, 1500)
    }
})

$(document).on('submit', '#search_resident_form_apartment', function (e) {
    e.preventDefault()

    let url = $(this).attr('action')
    let csrf = $(this).find('input[name=_token]').val()
    let searchInput = $(this).find('input[name=search_residents]')
    let apartmentId = $(this).find('input[name=apartment_id]').val()

    let btn = $(this).find('*[type=submit]')
    let btnTextDefault = $(btn).attr('data-default')
    let btnTextProcess = $(btn).attr('data-process')
    let btnTextSuccess = $(btn).attr('data-success')
    let btnTextError = $(btn).attr('data-error')

    if ($(searchInput).val().length > 2) {
        let sendData = {
            csrf: csrf,
            search: $(searchInput).val()
        }

        $(btn).text(btnTextProcess)
        $(btn).attr('disabled', true)

        $.ajax({
            url: url,
            method: 'get',
            headers: {
                'X-CSRF-TOKEN': csrf,
            },
            data: sendData,
            success: function (response) {
                $('#search-resident-list').html('')
                $('#search-resident-list__container').fadeIn()

                if (Object.keys(response.data).length !== 0) {
                    for (const [key, value] of Object.entries(response.data)) {
                        let str = '<b>' + value.f_name + '</b>'

                        if (value.email !== null) {
                            str += ' ' + value.email
                        }

                        if (value.phone !== null) {
                            str += ' ' + value.phone
                        }

                        let status = '';

                        if (value.apartments !== undefined && value.apartments !== null) {
                            if (Object.keys(value.apartments).length !== 0) {
                                for (const [objKey, objValue] of Object.entries(value.apartments)) {
                                    if (objValue.id === Number(apartmentId)) {
                                        status = 'disabled';
                                    }
                                }
                            }
                        }

                        let userBtn = '<button class="js-add-to-apartment" data-user-id="' + value.id + '" ' + status + '>' + str + '</button>'
                        $('#search-resident-list').append(userBtn)
                    }
                } else {
                    let htmlString = '<span style="color: red;">' + $('#search-resident-list').attr('data-empty') + '</span>'

                    $('#search-resident-list').html(htmlString)

                    setTimeout(function () {
                        $('#search-resident-list').html('')
                    }, 3000)
                }

                $(btn).text(btnTextSuccess)

                setTimeout(function () {
                    $(btn).text(btnTextDefault)
                    $(btn).attr('disabled', false)
                }, 1000)
            },
            error: function (xhr, status, error) {
                var errors = JSON.parse(xhr.responseText);
                alert(errors.message)
            }
        });
    } else {
        $(btn).text(btnTextError)
        $(btn).addClass('btn-error')

        setTimeout(function () {
            $(btn).text(btnTextDefault)
            $(btn).removeClass('btn-error')
        }, 1500)
    }
})

$(document).on('click', '#search-resident-list .js-add-to-house', function (e) {
    e.preventDefault()

    let btn = $(this)
    let container = $(btn).parents('#search-resident-list')
    let userId = Number($(btn).attr('data-user-id'))
    let houseId = $(container).attr('data-house-id')
    let route = $(container).attr('data-route').toString()
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let btnTextDefault = $(btn).text()
    let btnTextProcess = $(container).attr('data-process')
    let btnTextSuccess = $(container).attr('data-success')
    let btnTextError = $(container).attr('data-error')

    $(btn).text(btnTextProcess)
    $(btn).attr('disabled', true)

    let sendData = {
        csrf: csrf,
        house_id: houseId,
        user_id: userId,
    }

    $.ajax({
        url: route,
        method: 'post',
        headers: {
            'X-CSRF-TOKEN': csrf,
        },
        data: sendData,
        dataType: "json",
        success: function (response) {
            $(btn).text(btnTextSuccess)

            setTimeout(function () {
                $(btn).text(btnTextDefault)
            }, 1000)
        },
        error: function (xhr, status, error) {
            var errors = JSON.parse(xhr.responseText);
            $(btn).text(btnTextError)

            setTimeout(function () {
                $(btn).text(btnTextDefault)
                $(btn).attr('disabled', false)
            }, 2000)
            alert(errors.message)
        }
    });
})


$(document).on('click', '#search-resident-list .js-add-to-apartment', function (e) {
    e.preventDefault()

    let btn = $(this)
    let container = $(btn).parents('#search-resident-list')
    let userId = Number($(btn).attr('data-user-id'))
    let apartmentId = $(container).attr('data-apartment-id')
    let route = $(container).attr('data-route').toString()
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let btnTextDefault = $(btn).text()
    let btnTextProcess = $(container).attr('data-process')
    let btnTextSuccess = $(container).attr('data-success')
    let btnTextError = $(container).attr('data-error')

    $(btn).text(btnTextProcess)
    $(btn).attr('disabled', true)

    let sendData = {
        csrf: csrf,
        apartment_id: apartmentId,
        user_id: userId,
    }

    $.ajax({
        url: route,
        method: 'post',
        headers: {
            'X-CSRF-TOKEN': csrf,
        },
        data: sendData,
        dataType: "json",
        success: function (response) {
            $(btn).text(btnTextSuccess)

            setTimeout(function () {
                $(btn).text(btnTextDefault)
            }, 1000)
        },
        error: function (xhr, status, error) {
            var errors = JSON.parse(xhr.responseText);
            $(btn).text(btnTextError)

            setTimeout(function () {
                $(btn).text(btnTextDefault)
                $(btn).attr('disabled', false)
            }, 2000)
            alert(errors.message)
        }
    });
})


let phoneInput = document.querySelector('.phone-input')

if (phoneInput !== undefined && phoneInput !== null) {

    let inputContainer = phoneInput.closest('.phone-input-container')
    let errorMsg = inputContainer.querySelector(".error-msg");
    let validMsg = inputContainer.querySelector(".valid-msg");
    let form = inputContainer.closest('form')
    let btn = form.querySelector('*[type=submit]')

    const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    const iti = window.intlTelInput(phoneInput, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        autoFormat: true,
        autoHideDialCode: true,
        formatOnDisplay: false,
        autoPlaceholder: 'off',
        // geoIpLookup: function (callback) {
        //     jQuery.get("https://ipinfo.io?token=5725c1e585c014", function () {
        //     }, "jsonp").always(function (
        //         resp) {
        //
        //         console.log(resp)
        //         var countryCode = (resp && resp.country) ? resp.country : "";
        //         callback(countryCode);
        //     });
        // },
        hiddenInput: "phone",
        initialCountry: "de",
        preferredCountries: ["de"],
        nationalMode: true,
        placeholderNumberType: "MOBILE",
        separateDialCode: true,
    });


    const reset = () => {
        phoneInput.classList.remove("error");
        // errorMsg.innerHTML = "";
        // errorMsg.classList.add("valid-msg-hide");
        if(validMsg !== null && validMsg !== undefined){
            validMsg.classList.add("valid-msg-hide");
        }
    };

    phoneInput.addEventListener('blur', () => {
        reset();
        if (phoneInput.value.trim()) {
            if (iti.isValidNumber()) {
                phoneInput.classList.remove("error-input");
                if(validMsg !== null && validMsg !== undefined){
                    validMsg.classList.add("valid-msg-hide");
                }
                // btn.removeAttribute('disabled')
                // btn.setAttribute('disabled', false)
            } else {
                phoneInput.classList.add("error-input");
                // btn.setAttribute('disabled', true)
                const errorCode = iti.getValidationError();
                if(errorMsg !== null && errorMsg !== undefined){
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("valid-msg-hide");
                }
            }
        }
    });

    phoneInput.addEventListener('change', reset);
    phoneInput.addEventListener('keyup', reset);
}

let registrationInquiryForm = $('#registration-inquiry-form')
if (registrationInquiryForm.length !== 0) {

    $(document).on('submit', '#registration-inquiry-form', function (e) {
        e.preventDefault()

        let thisForm = $(this)
        let email = $(thisForm).find('input[name=email]').val()
        let phone = $(thisForm).find('input[name=phone]') ? $(thisForm).find('input[name=phone]').val() : $(thisForm).find('input[name=phone_input]').val()
        let f_name = $(thisForm).find('input[name=f_name]').val()
        let l_name = $(thisForm).find('input[name=l_name]').val()
        let street = $(thisForm).find('input[name=street]').val()
        let url = $(thisForm).attr('action')
        let csrf = $('meta[name="csrf-token"]').attr('content')

        let btn = $(thisForm).find('button[type=submit]')
        let btnTextDefault = $(btn).attr('data-default')
        let btnTextProcess = $(btn).attr('data-process')
        let btnTextSuccess = $(btn).attr('data-success')
        let btnTextError = $(btn).attr('data-error')

        let sendData = {
            f_name: f_name,
            l_name: l_name,
            email: email,
            phone: phone,
            street: street
        }

        $(btn).find('div').text(btnTextProcess)
        $(btn).attr('disabled', true)

        $.ajax({
            url: url,
            method: 'post',
            headers: {
                'X-CSRF-TOKEN': csrf,
            },
            data: sendData,
            success: function (data) {
                $(btn).find('div').text(btnTextSuccess)
                $('.js-registration-request-success').show()

                setTimeout(function () {
                    $('.close-popup').trigger('click')
                    $(btn).find('div').text(btnTextDefault)
                    $(btn).attr('disabled', false)
                    $(thisForm).find('input').val('')
                    $('.js-registration-request-success').hide()

                }, 5000)
            },
            error: function (error) {
                $('.js-registration-request-failed').show()
                alert(error.responseJSON.message)
                $(btn).find('div').text(btnTextError)
                setTimeout(function () {
                    $(btn).find('div').text(btnTextDefault)
                    $(btn).attr('disabled', false)
                    $('.js-registration-request-failed').hide()
                }, 3000)
            }
        });
    })
}


$(document).on('submit', '.js-inquiry-form', function (e) {
    e.preventDefault()

    let url = $(this).attr('action')
    let csrf = $('meta[name="csrf-token"]').attr('content')

    let btn = $(this).find('*[type=submit]')
    let btnTextDefault = $(btn).attr('data-default')
    let btnTextProcess = $(btn).attr('data-process')
    let btnTextSuccess = $(btn).attr('data-success')
    let btnTextError = $(btn).attr('data-error')

    let formData = new FormData(this)

    $(btn).text(btnTextProcess)
    $(btn).attr('disabled', true)

    $('.js-inquiry-form-failed').hide()

    $.ajax({
        url: url,
        method: 'post',
        headers: {
            'X-CSRF-TOKEN': csrf,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {

            if (data.status === true) {
                $('.js-inquiry-form').hide()
                $('.js-inquiry-form-success').fadeIn()
                $(btn).attr('disabled', false)
                $(btn).text(btnTextSuccess)
            } else {
                let errorsString = ''
                let errors = data.message

                for (const [key, value] of Object.entries(errors)) {
                    for (const [valueKey, valueData] of Object.entries(value)) {
                        let string = key + ': ' + valueData + '\n'
                        errorsString += string
                    }
                }

                alert(errorsString)
            }
        },
        error: function (xhr) {
            var errors = JSON.parse(xhr.responseText);
            $(btn).text(btnTextError)

            setTimeout(function () {
                $(btn).attr('disabled', false)
                $(btn).text(btnTextDefault)

                // $('.js-inquiry-form').hide()
                $('.js-inquiry-form-failed').fadeIn()
            }, 2000)
            alert(errors.message)
        }
    });
})

let contactsListItems = $('.content-list').find('.contacts-item')
if ($(contactsListItems).length > 5) {
    let hiddenItems = $(contactsListItems).slice(5)
    $(hiddenItems).each((key, item) => {
        $(item).css('display', 'none')
    })

    $(document).on('click', '.js-show-all-contacts', function (e) {
        e.preventDefault()

        if ($(this).attr('data-show') === '0') {
            $(hiddenItems).each((key, item) => {
                $(item).css('display', 'flex')
            })

            $(this).attr('data-show', '1')
            $(this).text('Hide')
        } else {
            $(hiddenItems).each((key, item) => {
                $(item).css('display', 'none')

                $(this).attr('data-show', '0')
                $(this).text('Show all')
            })
        }
    })
}

let decisionsList = $('.js-decisions-list').find('.content-box')
if ($(decisionsList).length > 8) {
    let hiddenDecisions = $(decisionsList).slice(9)
    $(hiddenDecisions).each((key, item) => {
        $(item).css('display', 'none')
    })

    $(document).on('click', '.js-show-all-decisions', function (e) {
        e.preventDefault()

        if ($(this).attr('data-show') === '0') {
            $(hiddenDecisions).each((key, item) => {
                $(item).css('display', 'block')
            })

            $(this).attr('data-show', '1')
            $(this).text('Hide')
        } else {
            $(hiddenDecisions).each((key, item) => {
                $(item).css('display', 'none')

                $(this).attr('data-show', '0')
                $(this).text('Show all')
            })
        }
    })
}

// let decisionsSearch = $('.js-decisions-search')
// if($(decisionsSearch).length !== 0){
//     $(decisionsSearch).on('input', function (e){
//         if($(this).val().length > 1){
//             $(this).addClass('inputChanged')
//             setTimeout(function (){
//                 $(decisionsSearch).removeClass('inputChanged')
//                 $(decisionsSearch).attr('disabled', true)
//                 $('.js-decisions-list').css('display', 'none')
//                 $('.js-loader').css('display', 'flex')
//
//                 let ajaxData = {}
//                 let ajaxRoute = '/dashboard/beschlusse'
//
//                 let ajaxRes = sendAjax(ajaxData, ajaxRoute, 'GET')
//                 console.log(ajaxRes)
//
//             }, 2500)
//         }
//     })
// }

async function sendAjax(data, route, method) {

    let sendData = new FormData();

    if (data !== null && data !== undefined) {
        for (const [key, value] of Object.entries(data)) {
            sendData.append(key, value);
        }
    }

    let requestOptions = {
        method: method,
        // body: sendData,
        redirect: 'follow',
        headers: {
            "Accept": "application/json",
        },
    };
    const response = await fetch(route, requestOptions);
    const res = await response
    const responseJson = res.json();
    return responseJson;
}

let sliders = document.querySelectorAll('.splide')
if (sliders.length > 0) {
    sliders.forEach((slider) => {
        new Splide(slider).mount();
    })
}

$(document).on('click', '.js-already-have-code', function (e) {
    e.preventDefault()
    $('#password-reset-code-step').show()
    $('#password-reset-request-step').hide()
})

$(document).on('click', '.js-return-to-code-request', function (e) {
    e.preventDefault()

    $('#password-reset-code-step').hide()
    $('#password-reset-request-step').show()
})

$(document).on('click', '.js-tab-link', function (e) {
    e.preventDefault();
    let container = $(this).parents('.tab-container')
    let links = $(container).find('.js-tab-link')
    let tabs = $(container).find('.tabcontent')
    let neededTabId = '#' + $(this).attr('data-href')
    let neededTab = $(container).find(neededTabId)

    if (!$(this).hasClass('active')) {
        $(links).removeClass('active')
        $(this).addClass('active')
        $(tabs).css('display', 'none')
        $(neededTab).css('display', 'block')
    }

    let page_title = document.title
    let url = new URL(window.location.href);
    let neededUrl = url.origin + url.pathname
    let url_string = '?tab=' + $(this).attr('data-href')

    window.history.pushState(null, page_title, neededUrl + url_string);
})


$(window).on('load', function () {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let neededTabInTabs = searchParams.get("tab")

    if (neededTabInTabs !== undefined && neededTabInTabs !== null) {
        let container = $('.tab-container')
        let links = $(container).find('.js-tab-link')
        let tabs = $(container).find('.tabcontent')
        let neededTabId = '#' + neededTabInTabs
        let neededTab = $(container).find(neededTabId)
        let neededTabLink = $('button[data-href=' + neededTabInTabs + ']')

        $(tabs).css('display', 'none')
        $(neededTab).css('display', 'block')
        $(links).removeClass('active')
        $(neededTabLink).addClass('active')
    }
});

$(document).on('submit', 'form.form-with-wysiwyg', function (e) {
    e.preventDefault();

    let editorFields = $('.wysiwyg-editor')
    if ($(editorFields).length !== 0) {
        $(editorFields).each((key, input) => {
            let neededHiddenInputName = $(input).attr('data-input')
            let neededHiddenInput = $('#' + neededHiddenInputName)
            let neededContent = $(input).find('.ql-editor').html()
            $(neededHiddenInput).val(neededContent)
        })
    }

    $(this)[0].submit()
})

let quillEditors = document.querySelectorAll('.wysiwyg-editor')
if (quillEditors.length !== 0) {
    let toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote'],

        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        // ['link', 'image'],
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
    ];


    quillEditors.forEach((editor) => {
        let neededId = '#' + editor.id

        new Quill(neededId, {
            modules: {
                // imageResize: {
                // },
                toolbar: toolbarOptions,
            },
            theme: 'snow'
        });
    })
}


$(document).on('click', '.js-download-file', function (e) {
    e.preventDefault()

    let url = $(this).attr('href')

    if ($(this).hasClass('js-fancy-doc')) {
        Fancybox.show([
            {
                src: url,
                type: "iframe",
                preload: false,
            },
        ]);
    } else if ($(this).hasClass('js-fancy-image')) {

        Fancybox.show([
            {
                src: url,
            },
        ]);
    }
})

$(document).ready(function () {
    var readURL = function (input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.profile-pic').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]);

            $('.p-info').fadeIn()
        }
    }

    $(".file-upload").on('change', function () {
        readURL(this);
    });

    $(".upload-button").on('click', function () {
        $(".file-upload").click();
    });
});


$(document).on('click', '.admin-panel-menu__btn', function (e) {
    e.preventDefault()
    let openText = $(this).attr('data-text-open')
    let closeText = $(this).attr('data-text-close')

    let adminMenuContent = $('.admin-panel-menu__content')

    if ($(this).hasClass('admin-menu-hided')) {
        $(adminMenuContent).fadeIn()
        $(this).removeClass('admin-menu-hided')
        $(this).text(closeText)
    } else {
        $(adminMenuContent).fadeOut()
        $(this).addClass('admin-menu-hided')
        $(this).text(openText)
    }
})

$(document).on('change', '.js-head-checkbox', function () {
    let checked = $(this).is(":checked")

    let allTBodyCheckbox = $('.wohneinheit table tbody').find('.wohneinheit-checkbox')

    if (checked === true) {
        $(allTBodyCheckbox).prop('checked', true);
    } else {
        $(allTBodyCheckbox).prop('checked', false);
    }
})

$(document).on('click', '.js-remove-entity-item', function (e){

    let text = $(this).attr('data-text')
    let answer = confirm(text)

    if(answer){
        $(this).parents('form').submit()
    }else{
        e.preventDefault()
    }
})

$(document).on('click', '.js-close-alerts', function (e){
    e.preventDefault()

    let errorContainer = $(this).parents('.alerts-block')
    if(errorContainer){
        $(errorContainer).fadeOut()
    }
})


$(document).on('click', '.js-custom-fancybox', function (e) {
    e.preventDefault()

    let url = $(this).attr('href')

    if ($(this).hasClass('js-fancy-video')) {
        Fancybox.show([
            {
                src: '<video controls class="modal-video" src="' + url + '"></video>',
                type: "html",
                preload: false,
            },
        ]);
    } else if ($(this).hasClass('js-fancy-image')) {
        Fancybox.show([
            {
                src: url,
            },
        ]);
    }
})

$(document).on('change', '.lang-switcher', function (e){
    let form = $(this).parents('form')
    $(form).submit()
})


$(document).on('submit', '.js-files-form', function (e) {
    e.preventDefault()

    let multipleFilesField = $(this).find('.field-upload-multiple-file')

    if($(multipleFilesField).length !== 0){
        let files = $(this).find('input[type=file]')[0].files;
        let fileContainer = $(this).find('.uploaded-file-container')
        let formFiles = $(fileContainer).find('.file')
        let hiddenNameInput = $(this).find('input[name=files_names]')

        let fileNamesObject = {}

        $(formFiles).each((key, item) => {
            let itemCustomName = $(item).find('.custom-file-title').val()

            if(itemCustomName == ''){
                itemCustomName = files[key].name
            }

            fileNamesObject[key] = itemCustomName;
        })

        $(hiddenNameInput).val(JSON.stringify(fileNamesObject))

        $(this)[0].submit()
    } else{
        $(this)[0].submit()
    }
})

$(document).ready(function () {

    function handleFileSelect(evt){
        let files = evt.target.files;
        let fileContainer = $('.uploaded-file-container')
        let customTextName = $(evt.target).attr('data-text')

        let template = `${Object.keys(files)
            .map(file => `<div class="file file--${file}" data-id="${file}">
         <div class="item-file-name">${files[file].name}</div><div class="item-file-type">${files[file].type}</div><div class="item-file-size">${files[file].size / 1000} kb</div><div class="item-file-custom-name"><div class="field-input-container"><input type="text" class="custom-file-title" name="custom-name-${file}" placeholder=" "> <label for="custom-name-${file}">${customTextName}</label></div></div>
        </div>`).join("")}`

        $(fileContainer).html(template)
    }

    $('.js-trigger-file').on('click', function (e){
        e.preventDefault();
        $(".js-hidden-file-input").click();
    })

    $(".js-hidden-file-input").on('change', handleFileSelect)
})


$(document).on('click', '.js-change-lang', function (e){
    e.preventDefault()

    let form = $(e.target).parents('form')
    let neededLang = $(e.target).attr('data-lang')
    $(form).find('input[name=lang]').val(neededLang)
    $(form)[0].submit()
})

$(document).on('click', '.burger-menu-button', function (e){
    e.preventDefault()

    let burger = $(this).parents('.burger-container')
    let menu = $(burger).find('.burger-menu')

    $(this).toggleClass('close')
    $(menu).toggleClass('overlay')

})

$(document).on('click', '.js-open-intercom', function (e){
    e.preventDefault()
    try {
        Intercom("show");
    } catch (e){
        console.log(e)
    }
})

if($('.js-select2-users-search').length !== 0){
    $('.js-select2-users-search').select2({
        minimumInputLength: 3,
        maximumInputLength: 20,
        multiple: true,
        dropdownParent: $('.select2-dropdown-container'),
        ajax: {
            url: ajaxUserSearch.ajaxUrl,
            method: 'GET',
            contentType: "application/json",
            dataType: 'json',
            delay: 250,
            headers: {
                'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content'),
            },
            data: function (params) {
                var query = {
                    search: params.term,
                }

                if(ajaxUserSearch.role_id !== ''){
                    query.role_id = ajaxUserSearch.role_id
                }

                return query;
            },
            processResults: function (data) {

                let users = data.data

                let formattedData = []

                if(users.length !== 0){
                    users.forEach((user) => {
                        let optionText = user.f_name + ' | ' + user.email

                        let userObject = {
                            "id": user.id,
                            "text": optionText
                        }

                        formattedData.push(userObject);
                    });
                }

                return {
                    results: formattedData
                };
            }
        }
    });
}
